export interface DestinationConfigProps {
  url?: string
  telegramLink?: string
}
export interface TonAdProps {
  adId: string
  adBlockId: string
  adFormat: 'image' | 'video'
  campaignId: string
  image: string
  icon: string
  isSupportVerified?: boolean
  popupImage?: string
  brandName?: string
  text: string
  buttonText?: string
  url?: string
  destination: {
    actionType: string
    url?: string
    destinationConfig: DestinationConfigProps
  }
}

export interface TonExchangeAdProps {
  icon: string
  adFormat: 'image' | 'video'
  inExchangeCampaignId: string
  outExchangeCampaignId: string
  isSupportVerified?: boolean
  image: string
  popupImage?: string
  brandName?: string
  buttonText?: string
  text: string
  destination: {
    destinationConfig: DestinationConfigProps
    actionType: string
    url?: string
  }
  url?: string
}

export interface DeviceInfo {
  currentResolution: string
  timeZone: string
  deviceId: string
}
export interface TonAdPopupShowOptions {
  blockId?: string
  tonAd?: TonAdProps
  telegramUserId?: string
  countdown?: number // 倒计时时长：10-30秒 默认 15s
  autoClose?: boolean // 倒计时结束后是否自动关闭
  showCloseButton?: ShowCloseButton // 是否显示关闭按钮
  closeAfterClick?: boolean // 点击后是否关闭
  onAdComplete?: (ad: TonAdProps) => void
  onAdShow?: (ad: TonAdProps) => void
  onAdClick?: (ad: TonAdProps) => void
  onAdClose?: () => void
  onAdError?: (error: any) => void
  onAdVerifyed?: (options: OnAdVerifyOptions) => void
}
export interface TonExchangeAdPopupShowOptions {
  exchangeId?: string
  exchangeAd?: TonExchangeAdProps
  telegramUserId?: string
  countdown?: number
  autoClose?: boolean
  onAdComplete?: (ad: TonExchangeAdProps) => void
  onAdShow?: (ad: TonExchangeAdProps) => void
  onAdClick?: (ad: TonExchangeAdProps) => void
  onAdVerifyed?: (options: OnExchangeAdVerifyOptions) => void
  onAdClose?: () => void
  onAdError?: (error: any) => void
}

export interface Destination {
  actionType: string //'visit.website' | 'join.telegram'
  destinationConfig: DestinationConfigProps
}

export interface OnExchangeAdVerifyOptions {
  success: boolean
  ad: TonExchangeAdProps
  message?: string
}
export interface OnAdVerifyOptions {
  success: boolean
  ad: TonAdProps
  message?: string
}

export enum ShowCloseButton {
  Always = 'always', // 一直显示
  Never = 'never', // 不显示
  After = 'after' // 倒计时结束后显示
}
